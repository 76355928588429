import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import {
  ForCard,
  FooterRecCard,
  IndustriesStatic,
} from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"
const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/cloud-security/",
    label: "For Cloud",
  },
]

const cloudSec = [
  {
    title: "RADICAL VISIBILITY",
    LinkUr: "/the-platform/",
    rec: "../images/for-security/rvLogo.png",
    content:
      "The ability of the cloud to allow rapid deployment and speed to market comes with challenges. Vigilant’s real-time visibility mediates risk and protects your advancing cloud architecture.",
  },
  {
    title: "BREACH RESPONSE POLICY",
    LinkUr: "/blog-pages/breach-response",
    rec: "../images/for-security/brpLogo.png",
    content:
      "Vigilant’s Titanium Security Certification activates our UNLIMITED Breach Response Policy. A benefit that is more important than ever in today’s ever-changing multi-cloud world.",
  },
  {
    title: "REDUCE RISK EXPOSURE",
    LinkUr: "/attack-surface-reduction/",
    rec: "../images/for-security/cloud-secure.png",
    content:
      "Vigilant uncovers and eliminates unnecessary attack surfaces. The cloud is dynamic and IoT structures are dispersed, requiring tools, automation and standards to enable advanced security behavior.",
  },
]

const resourcesList = [
  {
    title: "Vigilant365™",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Mid-size tech company",
    subtitle:
      "prevents unauthorized access to Microsoft 365 account with Vigilant. ",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf",
    cat: "SUCCESS STORY",
  },
  {
    title: "Fast Remediation",
    subtitle: " ",
    LinkUr: "/blog-pages/remediation",
    cat: "BLOG",
  },
]

const CloudSecurity = () => (
  <Layout>
    <Seo title="For Cloud" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-3">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>CLOUD SECURITY</h1>
            <h4>
              BUSINESS TODAY REQUIRES
              <br /> MODERN CLOUD SECURITY
            </h4>
            <p className="content">
              Ever-emerging cloud platforms require broad visibility, real-time
              detection, adversary awareness and performance protection across
              the entire range of your cloud threat landscape.
            </p>
            <Link to="/contact" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  get started now
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>{" "}
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>

    <section className="section-cards-3--for-card">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="card-feature-list">
              {cloudSec.map(data => {
                return (
                  <ForCard
                    altTxt={data.title}
                    title={data.title}
                    imgSrc={data.rec}
                    content={data.content}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-col-2-img--for-cloud grid--bg --for-it-ops-1">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h2>SECURING THE CLOUD</h2>
            <h6>
              Eliminating the Expanding Threat of Business Account Takeover
            </h6>
            <p>
              To see all that is happening in their environments presents
              operational hurdles for most companies. For real-time threat
              detection Vigilant365™ ingests all of the information coming out
              of Office365, alongside custom built alerting, allowing for 24/7
              visibility, even outside the security box for a proactive cloud
              security solution that closes the gaps while detecting critical
              threats.
            </p>
            <a
              href="https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf"
              className="nav-section--cta-btn"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  learn more
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>{" "}
            </a>
          </div>
          <div className="section-col-2">
            <StaticImage
              src="../images/for-security/Vigilant_ForCloudPortal.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="section-copy-img-l--for-cloud">
      <div className="container">
        <div className="row">
          <div className="section-col-2">
            <div className="side-binder">
              <h6>CLOUD WORKLOAD PROTECTION</h6>
              <h3>MANAGED ENDPOINT DETECTION &amp; RESPONSE</h3>
            </div>
            <p>
              There are many benefits to cloud services, both private and
              public: business agility, ease of use, cost efficiency, dynamic
              allocation of resources and scope of functionality. However,
              alongside those advantages comes a far more complicated workflow
              and therefore, an expanded attack surface and opportunities for
              blind spots.{" "}
            </p>
            <p>
              The cloud provider is responsible for security of the cloud’s
              physical infrastructure, but the user needs a partner for security
              inside the cloud: applications, identity management, data and
              encryption. That is why Vigilant’s Managed Endpoint Detection and
              Response (MEDR) provides one platform to protect all workloads:
              servers, virtual, multi-cloud and containers.
            </p>
            <p>
              The most important word in MEDR is “Managed.” Vigilant’s
              whole-team security model provides continuous oversite of
              comprehensive cloud visibility to enable proactive threat hunting
              and forensic investigation throughout your cloud workloads.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  schedule a call
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>{" "}
            </Link>
          </div>
          <div className="section-col-1 border-gradient border-gradient-blue">
            <div className="block--gradient">
              <div className="block--gradient-feature">
                <h4>The process is as simple as it is collaborative:</h4>
                <ol>
                  <li>Schedule a security architecture discussion.</li>
                  <li>
                    Choose the service with the best features for your company.{" "}
                  </li>
                  <li>Integrate the MEDR service into your environments.</li>
                  <li>
                    Experience protection and Vigilant’s Unlimited Cloud Breach
                    Response Policy.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-logo-6--for-cloud">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3 className="text-center">
              Vigilant provides managed network detection and managed endpoint
              detection across <br />
              providers from on-prem to remote to IoT to cloud. We put humans
              back in the game.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="section-col-2">
            <ul className="section--logo-list">
              <li>
                <StaticImage
                  src="../images/placeholder/icon-aws.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Amazon Web Services"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/icon-win.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Microsoft"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/trend-icon.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Trend Micro"
                  className="img-fluid"
                />
              </li>
              <li>
                <StaticImage
                  src="../images/placeholder/hawk.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="CrowdStrike"
                  className="img-fluid"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="section-blocks-6">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="side-binder">
              <h6>INDUSTRIES</h6>
              <h3>CLOUD SECURITY</h3>
            </div>
          </div>
          <div className="section-col-2">
            <IndustriesStatic />
          </div>
        </div>
      </div>
    </section>

    <div>
      <div className="grid--bg --for-it-ops-1--center">
        <section className="section-client-quote">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <div className="section-col-1--content">
                  <h3>
                    “The visibility provided by Vigilant365™ helped me save my
                    own O365 account from possible takeover by a foreign entity.
                    I received an alert that there was a suspicious login
                    attempt on my account from someone overseas and Vigilant
                    provided the needed steps to ensure nothing happened.”
                  </h3>
                  <hr />
                  <h6>- TECHNOLOGY CLIENT</h6>
                  <p className="pBlue">
                   (For security reasons, Vigilant never reveals the identity of
                    our clients)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section-resources">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3>RESOURCES</h3>
            </div>
            <div className="section-col-2">
              <ul className="resources-list">
                {resourcesList.map(data => {
                  return (
                    <FooterRecCard
                      altTxt={data.title}
                      title={data.title}
                      subTitle={data.subtitle}
                      recCat={data.cat}
                      LinkUrl={data.LinkUr}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default CloudSecurity
